<template>
  <div 
    class="task-local" 
    :class="{'recog-selected': aiMode === mode && aiRunOn === 'device' }" 
    @click="viewTask()"
  >
    <div class="title">
      <img :src="taskIcon" alt="">
      <div class="name">{{ taskTitle }}</div>
    </div>
    <div class="item">
      <div class="label">{{ $t('ai_updated_time')/*更新時間*/ }}</div>
      <div v-show="aiRunOn === 'device' && aiMode === mode" class="icons">
        <div v-if="permissionV2.deviceCtrl > 1 && permissionV2.device > 1 && !isEdit" class="icon" @click.stop="goEditMode">
          <img src="@/assets/icons/pen.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'TaskCardDevice',
  props: [ 'mode' ],
  data() {
    return {}
  },
  computed: {
    ...mapState(['permissionV2']),
    ...mapState('aibox', ['aiMode', 'aiRunOn', 'isEdit']),
    taskIcon() {
      if (this.mode === 'or') return require('@/assets/icons/OR.svg')
      else if (this.mode === 'fr') return require('@/assets/icons/FR.svg')
      else if (this.mode === 'lpr') return require('@/assets/icons/LPR.svg')
      else return ''
    },
    taskTitle() {
      if (this.mode === 'or') return this.$t('ai_object_recognition')/*物件辨識*/
      else if (this.mode === 'fr') return this.$t('ai_person_recognition')/*人物辨識*/
      else if (this.mode === 'lpr') return this.$t('ai_license_plate_recognition')/*車牌辨識*/
      else return ''
    },
  },
  methods: {
    ...mapMutations('aibox', [
      'updateAiMode', 
      'updateAiRunOn', 
      'updateIsEdit', 
      'updateShowAiboxPortal', 
      'updateParamAiboxPortal'
    ]),
    viewTask() {
      // 若是編輯模式下，切換辨識任務時，需先挑窗詢問是否要取消編輯
      if (this.isEdit) {
        const payload = {
          aiRunOn: 'device',
          aiMode: this.mode,
        }
        this.updateParamAiboxPortal({ info: 'cancelEdit', action: 'switchTask', payload })
        this.updateShowAiboxPortal(true)
      } else {
        this.updateAiRunOn('device')
        this.updateAiMode(this.mode)
      }
    },
    goEditMode() {
      // if (!this.isSettingReady) return
      this.updateIsEdit(true)
    },
  }
}
</script>

<style lang="scss" scoped>
.task-local {
  background: #39425D;
  border-radius: 8px;
  padding: 16px 12px;
  margin-bottom: 8px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  cursor: pointer;
}

.recog-selected {
  background: #4A5C78;
  border: 3px solid #FFE99F;
}

.title {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  line-height: 36px;
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}

.status {
  position: absolute;
  top: 12px;
  right: 0;
  max-width: 150px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 6px 12px;
  background: #6E7D93;
  border-radius: 8px 0px 0px 8px;
}

.error {
  background: #F94144;
}

.item {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 6px;
  position: relative;
}

.item .label {
  width: 40%;
  color: #6E7D93;
}

.item .value {
  width: 60%;
  word-break: break-all;
}

.time {
  color: #8CC24D;
}

.orange {
  color: #D8A848;
}

.red {
  color: #F94144;
}

.icons {
  display: flex;
  position: absolute;
  right: 0px;
  top: -3px;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.icon:hover {
  background: #FFFFFF33;
}

.icon img {
  width: 20px;
  height: 20px;
}

.icons .icon:first-of-type {
  margin-right: 4px;
}
</style>